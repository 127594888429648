.mts-10 {
  margin-top: 10px;
}

.side_bar {
  position: absolute;
  width: 88px;
  height: 768px;
  left: 93px;
  top: 265px;
}

.ant-layout-content {
  margin: 0 !important;
  padding: 0 !important;
  background: #E5E5E5;
}

.ant-card {
  background: #E5E5E5;
}

.ant-card-body {
  padding: 20px;
}
.ant-card-head {
  border-bottom: 0;
}
.report_Card > .ant-card-body {
  padding: 0px;
}

.ant-layout-sider-expanded {
  background: #FFF;
  z-index: 22;
  min-width: 220px !important;
}

.ant-layout-sider {
  background: #FFF;
  z-index: 22;
}

/*  */
.trigger {
  /* padding-left: 70px; */
  /* padding: 0 24px; */
  font-size: 18px;
  /* line-height: 64px; */
  cursor: pointer;
  transition: 0.3s;
  /* float: right; */
  /* height: 15px; */
  /* width: 15px; */
  position: absolute;
  right: -12px;
  padding-top: 1rem;
  z-index: 1;
  color: #97D9F6
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 52px;
  margin: 8px;
  background: url('/public/images/logo.svg');
  background-repeat: no-repeat;
}

.vertical_menu {
  position: absolute;
  left: 0%;
  right: 14.77%;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
}

.menu_custom {
  padding-top: 20px;
}

table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}

/* .ant-table {
  line-height: 0.58;
} */


/* card layout */

.card_layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  /* gap: 1.2rem; */


  /* align-items: flex-start; */
  /* padding: 16px; */

  width: 300px;
  /* height: 289px; */
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #044A2A;

  /* height: 25px; */

  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 17px; */

  /* color: #044A2A; */
}

.cart_title {
  /* width: 322px; */
  height: 17px;

  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;

  color: #044A2A;
}

.report_Card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 10px;  */
  gap: 10px;
  /* height: 100%; */
  width: 350px;
  /* height: 250px; */

  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.welcome_msg {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  /* identical to box height */

  text-align: center;

  color: #044A2A;
}

.quote_msg {
  font-family: 'Roboto';
  display: grid;
  place-items: center;
}

.quote_linear {
  font-size: 13px;
  font-weight: 600;
}

@font-face {
  font-family: 'Knockout';
  src: local('Knockout'), url(./fonts/Knockout.otf) format('opentype');
}