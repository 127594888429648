@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  /* background: #f5f5f5; */
  background: #E5E5E5;
}

h6 {
  color: #00703C;
}

img.Logo-Img {
  cursor: pointer;
  /* width: 120px; */
  height: 55px;
  padding: 0.35rem 1.25rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-page-header-ghost-wrapper {
  background-color: #f5f5f5;
}

.ant-card-body {
  /* background-color: #EAF9FF; */
  height: 100%;
  padding: 32px;
}

.ant-card-head {
  padding: 0;
}

/* .ant-card-head-title {
  background-color: #95C7ED;
  padding: 12px;
  margin: 0px;
  color: #002060;
  font-size: larger;
} */

.ant-card-head-title {
  /* background-color: #FFC325; */
  /* background-color: #498278; */
  /* background-color: #b8a22b; */
  padding: 12px;
  /* padding: 12px 1.5rem; */
  margin: 0px;
  color: #000;
  /* color: #fff; */
  font-size: 20px;
  font-weight: 600;
  /* background-color: #EAF9FF; */
}

.page-header-title {
  background-color: #95C7ED;
  padding: 12px;
  margin: 0px;
  color: #002060;
  font-size: larger;
}

.parent-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1.2rem;
}

.week-table-container {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: flex-start;
}

.week-table-container-store {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

.ParentGrid {
  display: grid;
  grid-template-columns: 1fr;
}

.report-listItem {
  border: 1px solid #e3e3e3;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0 0 4px #00703C;
  min-width: 250px;
}

.report-listItem:hover {
  border: 1px solid #00703C;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0 0 4px #00703C;
}

.total-system-sales-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clover-retail-price,
.fc-lease-details {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.year-picker {
  padding: 0px;
}

.ant-segmented.digital-tabs {
  font-size: 10px;
  font-weight: 600;
}

img.DownLoadExcel {
  height: 32px;
  padding: 0px 4px 0px 4px;
}

.report-content-text {
  padding: 0.5rem 0rem;
}

.report-text-wrap {
  white-space: nowrap !important;
  padding-left: 0.8rem;
}

.report-sub-heading>.fa-poll {
  vertical-align: middle;
  text-decoration: none;
  font-size: 12px;
  padding-right: 0.7rem;
  /* padding-right: 1.1rem; */
  color: #D41042;
  /* color:  #b8a22b; */
}



.report-sub-heading {
  vertical-align: middle;
  text-decoration: none;
  font-size: 12px;
  color: #00703C;
  font-family: 'Roboto';
  white-space: pre-wrap;
  word-wrap: break-word;
  /* word-break: break-all; */
  /* white-space: normal; */
  /* display:block; */
}

.custom-tooltip {
  padding: 1rem;
  background: #EAF9FF;
  color: #000000;
  border: none;
}

.ant-page-header-heading-left {
  display: flex;
  font-weight: 700;
  gap: 0.5rem;
}

.table-container,
.table-container-redeem {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  width: 100%;
  overflow: auto;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 5px;
  font-weight: 400;
  font-size: 10px;
}

/* tr:nth-child(even) {
   background-color: #dddddd; 
}
*/

.digital-sales-container-child {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.digital-sales-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.digital-container-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container-overview,
.redeem-grid-container {
  overflow: hidden;
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.ytd-grid-item {
  background: #fff;
}

.ytd-grid-item .ant-card {
  background: #fff;
}

.grid-container-overview {
  margin: 1rem;
}

/* .redeem-grid-container {
  height: 150vh;
} */

.three {
  grid-row: 2;
  grid-column: 1/3;
}

.sub-heading-digital {
  font-size: 13px;
  font-weight: 700;
  word-wrap: break-word;
}

.sub-heading {
  font-size: 16px;
  font-weight: 600;
}


.site-card-wrapper {
  height: 100%;
  overflow: auto;
  background: #E5E5E5;
  /* background: url(/public/dashboard-background.jpg) center center / cover no-repeat fixed; */
  padding: 12px;
  margin: 0px;
  font-size: larger;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-dashboard-container-header {
  padding: 1rem 0rem 2rem 0rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}
.main-dashboard-container {
  /* height: 40%; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2.5rem;
}

.main-dashboard-item {
  font-family: 'Verdana';
  border: 1px solid #D41042;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF !important;
  width: 195px;
  height: 195px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 1rem !important;
  /* border-radius: "5px";
  border-bottom: 3px solid #D41042;
  box-shadow: 0px 0 1px rgba(151, 217, 246, 0.2); */

}

.main-dashboard-child-item {
  /* display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center; */
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
  text-align: center;
}
.main-dashboard-child-item > img {
  /* height: 50%; */
}

path.recharts-rectangle.recharts-tooltip-cursor {
  fill: none;
}

.show-monthly-selection {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.table-td.btn.btn-link {
  font-size: 12px;
  font-weight: 600;
}

b.comp-sales {
  font-size: 13px;
  font-weight: 600;
  /* word-wrap: break-word; */
}

.norecords-available {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  background-color: #f4f6f7;
  color: #84898c;
  width: 100%;
  font-size: 0.875rem;
}

.ranking-btn-group {
  display: grid;
  place-items: center;
}

.ranking-report-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}

.report-grid {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  background: #fff;
}


span.fa.fa-arrow-left {
  cursor: pointer;
}

.summary-table-container>table>thead>tr>th {
  font-weight: 700;
}

.summary-table-container-white>table>thead>tr>th {
  font-weight: 700;
}

.ant-table-title {
  font-weight: 600;
}

.summary-table-container table {
  margin-top: 5px;
  width: 260px;
  color: #00703C;
  background: #EAF9FF;
  border: 0px;
}

.summary-table-container-white table {
  margin-top: 5px;
  width: 260px;
  color: #00703C;
  background: #fff;
  border: 0px;
}

.table-container table,.legend-table table {
  color: #00703C;
  background: #EAF9FF;
  margin-bottom: 1rem;
  /* border: 1px solid #00703C; */
}

.table-container-redeem table {
  color: #00703C;
  background: #EAF9FF;
  margin-bottom: 1rem;
}

.summary-table-container>table>thead>tr>th,
td {
  border: 0px
}

/* .table-container>table>thead>tr>th,
td {
  border: 0.5px solid #95C7ED;
} */
.legend-table>table>thead>tr>th,.legend-table>table>tbody>tr>td {
  border: 0.5px solid #95C7ED !important;
}

.recharts-text .recharts-label {
  font-size: 16px;
  color: red !important;
}

.top-nav-bar {
  height: 50px;
  background: #00703C;
  padding: 0px;
  margin: 0px;
  font-size: 30px;
  font-weight: 600;
  color: white;
}


div#banner {
  width: 100%;
  height: 55px;
  display: flex;
  /* align-items: end; */
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* background: #00703C url('./../public/subHead.jpg') no-repeat; */
  background: #00703C;
}

.navbar-logo-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-card.ant-card-bordered.dashboard-antd-card,
.ant-card.ant-card-bordered {
  border: 0px;
}


.login-container {
  height: 50%;
  width: 100%;
  display: grid;
  place-items: center;
}

.login-card {
  /* border: 1px solid #002060; */
  border-radius: 4px;
  box-shadow: rgb(45 46 46 / 35%) 0px 4px 32px 0px, rgb(45 46 46 / 8%) 0px 4px 16px 0px, rgb(45 46 46 / 10%) 0px 0px 4px 0px;
  /* background-color: #ffffff; */
  background-color: rgb(255, 255, 255);
  width: 350px;
  height: 320px;
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  box-sizing: border-box;
  color: #000000;
  margin: 24px auto;
  /* color: #00703C; */

}

.login-card>.ant-card-body {
  background-color: rgb(255, 255, 255);
}

button.login-form-button {
  width: 100%;
  color: #fff;
  border-color: #00703C;
  background-color: #00703C;
}

button.login-form-button:hover {
  color: #00703C;
  border-color: #00703C;
  background: #fff;
}


img.Logo-Login-Img {
  height: 65px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #00703C;
  background-color: #00703C;
}


body::-webkit-scrollbar {
  width: 0.25em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #017BBC;
  outline: 1px solid #00703C;
}


td>span.red {
  font-weight: 600;
  font-size: larger;
  color: red;
}

td>span.green {
  font-weight: 600;
  font-size: larger;
  color: green;
}

td>span.orange {
  font-weight: 600;
  font-size: larger;
  color: orange;
}

.ant-btn-primary {
  color: #fff;
  border-color: #00703C;
  background: #00703C;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

button.ant-btn.ant-btn-primary.btn-back {
  color: #97D9F6;
  border-color: #97D9F6;
  border-width: 1.5px;
  background: #FFFFFF;
  margin-right: 0.25rem;
  border: 1px solid #97D9F6;

}



.clover-retail-search {
  display: flex;
  justify-content: center;
  gap: 1rem;
  /* margin-bottom: 1rem; */
}

.clover-search-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.clover-retail-grid-header,
.fc-lease-grid-header,
.stores-list-grid-header,
.store-tracking-grid-header,
.ceo-ranking-grid-header,
.ceo-ranking-report-header {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}


.fc-discrepancy-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.fc-discrepancy-card-items {
  padding: 0.5rem;
  height: 75px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid grey;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.fc-discrepancy-images {
  justify-self: end;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
}


.fc-discrepancy-store-id {
  font-weight: 600;
  width: 90px;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.25rem;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: center;
}

.fc-discrepancy-rank {
  font-weight: 600;
  padding-right: 0.5rem;
  font-size: 1.2rem;
}

.fc-discrepancy-card-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 3rem
}

img.flag-image {
  width: 32px;
  height: 28px;
}


.nav-active-item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

/* model */

.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}

.sidebar {
  width: 280px;
  max-height: 250px;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #C6D2E0;
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 1;
  transition: 0.5s;
}

.sidebar.active {
  right: 0;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #00703C;
  width: 100%;
  height: 55px;
  display: flex;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

/* .sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
} */

/* .sd-body {
  padding: 1px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
} */

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}

.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.profileImage {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  line-height: 1.8rem;
  /* width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #004D3C;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  line-height: 1.8rem;
  margin: 2rem 0; */
}

.proHead {
  color: #fff;
  /* border-radius: 0.25rem 0.25rem 0 0; */
  padding: 15px;
  position: relative;
  background: #04a9f5;
}

.profileHeading {
  font-size: 16px;
  padding-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ant-switch-checked {
  background: #00703C;
}

.ant-table-thead .ant-table-cell {
  font-size: 12px;
}

/* .ant-table-tbody > tr > td {
border-bottom: 0px solid #f0f0f0;
} */

.login-username {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

.login-role {
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: end;
}

thead>tr>th.ant-table-cell {
  color: #D41042;

}

.table-div {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  /* border-bottom: 3px solid #D41042; */
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.cumulative-heading {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  /* border-bottom: 3px solid #D41042; */
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.ranking-search-div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

td>span.red-td {
  font-weight: 600;
  font-size: larger;
  color: red;
}

td>span.green-td {
  font-weight: 600;
  font-size: larger;
  color: green;
}

.no-records-available {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 100%;
  background: #fff;
}

.stores-section-summary {
  display: flex;
  justify-content: center;
}

.ant-layout {
  min-height: 100vh;
}

.ant-descriptions-item-label {
  font-size: 0.75rem;
  font-weight: 700;
  color: #00703C;
}

.ant-descriptions-item-content {
  font-size: 0.7rem;
  font-weight: 600;
  color: #00703C;
}

.store-link {
  color: #00703C;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-block;
  align-items: baseline;
}

span.text-red-600 {
  font-weight: 600;
  color: red;
}

span.text-orange-600 {
  font-weight: 600;
  color: orange;
}

span.text-yellow-600 {
  font-weight: 600;
  color: yellow;
}

.ant-layout-header {
  padding: 0 25px;
}

.fc-section-summary {
  display: flex;
  flex-direction: column;
}

.fc-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.sales-history-link {
  font-size: 11px;
  cursor: pointer;
}

.clover-search-subitems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.ant-table {
  overflow: auto;
}

.ant-descriptions-title {
  padding-left: 5px;
}




td.color-green {
  color: green;
}

td.color-red {
  color: red;
}


.border-red {
  border-right-width: 9px;
  border-right-color: red;
  background-color: rgb(255, 0, 0, 0.1);
}

.border-orange {
  border-right-width: 9px;
  border-right-color: orange;
  background-color: rgb(255, 165, 0, 0.1);
}

.border-yellow {
  border-right-width: 9px;
  border-right-color: yellow;
  background-color: rgb(255, 255, 0, 0.1);

}

.border-green {
  border-right-width: 9px;
  border-right-color: green;
  background-color: rgb(60, 179, 113, 0.1);
}

/* .ant-segmented-item-selected {
  background-color: #D41042;
  color: #ffffff;
}

.ant-segmented-item-selected:hover, .ant-segmented-item-selected:focus {
  background-color: #D41042;
  color: #ffffff;
}

.ant-segmented-item:hover, .ant-segmented-item:focus {
  color: #262626;
} */

.total-system-sales,
.rewards-redeem-report {
  background: #fff;
}


/* .ant-picker-header-super-prev-btn ,.ant-picker-header-super-next-btn{
  display: none;
  pointer-events: none;
}
.ant-picker-year-btn {
  pointer-events: none;
} */

.redeem-grid-container.single-grid-item {
  grid-template-columns: 1fr;
  /* height: 320vh; */
}

.highest-per-summary {
  font-size: 12px;
}

span.per-heading {
  /* color: #D41042; */
  font-weight: 700;
}

span.card-heading-overview-item {
  font-size: 15px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(212, 16, 66, 0.23);
}

.ant-menu-item-selected {
  color: #D41042;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #D41042;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #D41042;

}

.ant-segmented-item {
  background: #fff;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.ant-segmented-item-selected {
  color: #fff;
  border-radius: 0px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  background: #D41042;
  border: 1px solid #D41042;
}

.ant-segmented-item-selected:hover,
.ant-segmented-item-selected:focus {
  color: #fff;
}

.ant-segmented-group {
  border: 1px solid rgba(0, 0, 0, 0.49);
}

table.discripancy-table {
  background: #FFFFFF;
  border-radius: 4px;
  border-collapse: collapse;
}

table.discripancy-table>tr>th {
  color: #C20430;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}
table.discripancy-table>tr {
  text-align: center;
  height: 50px !important;
  padding: 1rem;
  border: 1px solid rgba(29, 36, 46, 0.3) !important;
}
table.discripancy-table>tr.data-tr {
  height: 70px !important;
}

.discripancy-no-data,table.discripancy-table>tr>td  {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #1D242E;
}
.fc-discripency-action-column {
  display: flex;
  align-items: center;
  gap:0.85rem;
}
td.fc-discripency-action-row {
  position: relative;
}
.store-new-one {
  position: absolute;
  right: 0;
  top: 0;
}
.discrepancy-ritasId {
  cursor: pointer;
  font-weight: 600;
}

/* .ant-tooltip-inner,.ant-tooltip-arrow-content {
  background: #876AC6 !important;
} */
td.Rank-Column {
  position: relative;
}
div.show-color.red {
  background: red;
}
div.show-color.orange {
  background: orange;
}
div.show-color.yellow {
  background: yellow;
}
div.show-color.green {
  background: green;
}
div.show-color:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  border-top: 7px solid white;
  border-bottom: 7px solid white;
  width: 0;
}
div.show-color.red:before {
  border-left: 7px solid red;
}
div.show-color.orange:before {
  border-left: 7px solid orange;
}
div.show-color.yellow:before {
  border-left: 7px solid yellow;
}
div.show-color.green:before {
  border-left: 7px solid green;
}
.cursor-pointer {
  cursor: pointer;
}

.ant-table-wrapper.discripancy-table td.ant-table-cell {
  font-size: 14px !important;
}
/* .discripancy-table .ant-table-body {
  max-height: calc(90vh - 127px);
} */
.fc-discrepancy-report-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


.ant-layout.ant-layout-has-sider {
  overflow: unset;
}

aside.ant-layout-sider {
  height: 100vh;
  position: sticky;
  top: 0;
}
.ant-layout-sider-children {
  position: relative;
}
ul.menu_custom.logout_menu {
  position: absolute;
  bottom: 0;
}

.rightArrowCollapse {
  position: absolute;
  left: 80px;
}
.leftArrowCollapse {
  position: absolute;
  left: 200px;
}

.table-container-grid {
  display: flex;
  justify-content: center;
  gap:2rem;
  flex-wrap: wrap;
  padding: 2rem 0rem;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 1px;
}

.logo-item {
  padding: 0.25rem 0rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.logo-header {
  font-family: 'Knockout';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  align-self: center;
  color: #D31145;
}
@media only screen and (min-device-width: 1800px) and (max-device-width: 3000px) {
  .Main-Layout {
    display: flex;
    justify-content: center;
  }

  .Main-Layout {
    max-width: 1800px;
    margin: auto;
  }

  .App {
    background-color: #F5F5F5;
  }
}

/* Media Queris for responsive */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .report-listItem {
    width: 365px;
  }

  .fc-discrepancy-card-items {
    min-height: 75px;
    height: auto;
  }

  .fc-discrepancy-images {
    flex-wrap: wrap;
  }

  .clover-retail-price,
  .fc-lease-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .ranking-report-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .fc-discrepancy-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .grid-container-overview,
  .redeem-grid-container {
    font-size: 12px;
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
  }

  .three {
    grid-row: 3;
    grid-column: 1;
  }


}

/* Portrait and Landscape */
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .ant-layout.ant-layout-has-sider {
    overflow: hidden;
  }
  aside.ant-layout-sider {
    height: 100vh; 
    position: sticky; 
    top: 0; 
  }
  .ant-layout-sider-children {
    position: relative;
  }
  ul.menu_custom.logout_menu {
    position: absolute;
    bottom: 20px;
  }
  
  .fc-discrepancy-card-items {
    min-height: 75px;
    height: auto;
  }

  .fc-discrepancy-images {
    flex-wrap: wrap;
  }

  img.Logo-Img {
    /* width: 120px; */
    height: 45px;
    padding: 0.35rem 1.25rem;
  }

  .fc-discrepancy-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .clover-retail-price,
  .fc-lease-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .total-system-sales-header {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .ranking-report-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .year-picker {
    padding: 4px;
  }

  .ant-segmented.digital-tabs {
    font-size: 10px;
    font-weight: 600;
  }

  img.DownLoadExcel {
    height: 40px;
    padding: 4px;
  }

  .clover-retail-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-dashboard-container {
    height: 20%;
  }


  .main-dashboard-item {
    width: 155px;
    height: 155px;
    font-size: 0.65rem;
    padding: 0.2rem;
  }
  .main-dashboard-child-item > img {
    height: 85%;
  }

  .ant-card-head-title {
    font-size: 15px;
  }

  .report-listItem {
    width: 365px;
  }

  b.comp-sales {
    font-size: 12px;
    font-weight: 500;
    /* word-wrap: break-word; */
  }

  .sub-heading {
    font-size: 13px;
    font-weight: 700;
    word-wrap: break-word;
  }

  .grid-container-overview,
  .redeem-grid-container {
    font-size: 12px;
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
  }

  .table-container,
  .table-container-redeem {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
    overflow: auto;
  }

  .three {
    grid-row: 3;
    grid-column: 1;
  }

  .week-table-container,
  .week-table-container-store {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .ant-layout.ant-layout-has-sider {
    overflow: hidden;
  }
  aside.ant-layout-sider {
    height: 100vh;
    position: sticky; 
    top: 0; 
  }
  .ant-layout-sider-children {
    position: relative;
  }
  ul.menu_custom.logout_menu {
    position: absolute;
    bottom: 20px;
  }
  .fc-discrepancy-card-items {
    min-height: 75px;
    height: auto;
  }
  .fc-discrepancy-report-card-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }
  .fc-discrepancy-select-fc {
    align-self: self-end !important;
  }

  .fc-discrepancy-images {
    flex-wrap: wrap;
  }

  img.Logo-Img {
    /* width: 120px; */
    height: 45px;
    padding: 0.35rem 1.25rem;
  }

  .fc-discrepancy-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .clover-retail-price,
  .fc-lease-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .ranking-report-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .total-system-sales-header {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .year-picker {
    padding: 4px;
  }

  img.DownLoadExcel {
    height: 40px;
    padding: 4px;
  }

  .ant-segmented.digital-tabs {
    font-size: 10px;
    font-weight: 600;
  }

  .main-dashboard-container {
    height: 20%;
  }

  .clover-retail-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-dashboard-item {
    width: 155px;
    height: 155px;
    font-size: 0.65rem;
    padding: 0.2rem;
  }
  .main-dashboard-child-item > img {
    height: 85%;
  }

  .ant-card-head-title {
    font-size: 15px;
  }

  .report-listItem {
    /* max-width: 380px;
    min-width: 264px; */
    width: 320px;
  }

  .sub-heading {
    font-size: 13px;
    font-weight: 700;
    word-wrap: break-word;
  }

  b.comp-sales {
    font-size: 12px;
    font-weight: 500;
    /* word-wrap: break-word; */
  }

  .table-container,
  .table-container-redeem {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
    overflow: auto;
  }

  .grid-container-overview,
  .redeem-grid-container {
    font-size: 12px;
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
  }

  .three {
    grid-row: 3;
    grid-column: 1;
  }

  .week-table-container,
  .week-table-container-store {
    display: grid;
    grid-template-columns: 1fr;
  }
}
