.tbl {
    border-collapse: collapse;
    width: 100%;
}


.store-summary-prior-current {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.summary {
    border: 2px solid black;
    border-radius: 5px;
    width: 290px;
    box-shadow: 3px 3px 3px #888888;
}

.ident {
    border: none;
    background-color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    font-size: large;
}

.category {
    border: 1px solid black;
    background-color: #C0C0C0;
    font-weight: bold;
    text-align: center;
    padding: 3px;
}

.negative {
    color: #CF132B;
}

.data {
    border: 1px solid black;
    padding: 2px;
    text-align: center;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
    .ant-segmented-item-label {
        min-height: 25px;
        padding: 0px 6px;
        line-height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
        font-size: 11px;
        font-weight: 600;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .ant-segmented-item-label {
        min-height: 25px;
        padding: 0px 6px;
        line-height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
        font-size: 11px;
        font-weight: 600;
    }

}